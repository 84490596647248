// @media screen and (min-width: 1700px) {
//   .searchSuggestionsBlock {
//     height: 24rem;

//     .searchSuggestionsRow {
//       flex-direction: row;

//       .searchSuggestionsList {
//         width: 25%;
//       }

//       .topProductsList {
//         width: 75%;
//       }
//     }
//   }
// }

// @media screen and (min-width: 1700px) {
//   .searchSuggestionsList {
//     width: auto !important;
//   }
// }

// @media screen and (max-width: 1700px) {
//   .searchSuggestionsRow {
//     flex-direction: column;
//   }

//   .searchSuggestionsList {
//     width: 100%;
//   }

//   .topProductsList {
//     margin-top: 1rem;
//   }
// }

// .stickyTop {
//   position: sticky;
//   top: 0;
//   z-index: 1020;

//   @media screen and (max-width: 1024px) {
//     &.positionRelativeMob {
//       position: relative !important;
//       z-index: 1050;

//       &:after {
//         position: fixed;
//       }
//     }
//   }
// }

// .header {
//   background: #0079ba;

//   .mobileHeader {
//     position: relative;
//     z-index: 100;
//   }

//   .verticalNav {
//     display: flex;
//     align-items: center;
//     cursor: pointer;

//     @media screen and (min-width: 1023px) and (max-width: 1200px) {
//       margin-right: 0px !important;
//     }

//     .menuIcon {
//       caret-color: transparent;
//       margin-right: 15px;
//       width: 40px;

//       @media screen and (max-width: 375px) {
//         margin-right: 3px;
//       }

//       span {
//         display: block;
//         width: 32px;
//         height: 2px;
//         background-color: #fff;
//         margin: 7.5px 0;
//         position: relative;
//         -webkit-transition: 0.4s ease;
//         transition: 0.4s ease;

//         &:nth-child(2) {
//           width: 20px;
//         }
//       }

//       &.isOpen {
//         span {
//           &:nth-child(1) {
//             display: none;
//           }

//           &:nth-child(2) {
//             transform: rotate(45deg);
//             top: 3px;
//             width: 33px;
//           }

//           &:nth-child(3) {
//             transform: rotate(-45deg);
//             top: -5px;
//             width: 33px;
//           }
//         }
//       }
//     }

//     .allSports {
//       line-height: 1.2;
//       font-weight: 600;
//       color: white;
//       text-transform: uppercase;
//     }
//   }

//   .decathlonLogo {
//     border: 1px solid #e8e7ef;

//     img {
//       max-width: 150px;
//     }
//   }

//   .geoBar {
//     padding: 15px 0;

//     span {
//       display: flex;
//       flex-direction: row;

//       b {
//         color: #ffea28;
//         font-size: 1rem;
//       }
//     }
//   }

//   .usefulLinks {
//     position: relative;

//     img {
//       margin: 0 auto 2px;
//     }

//     a {
//       padding: 10px 0;

//       @media screen and (min-width: 1023px) and (max-width: 1200px) {
//         margin-right: 12px !important;
//       }
//     }

//     p {
//       color: #fff;
//       font-size: 13px;
//     }

//     .myaccountDropdown {
//       position: absolute;
//       top: 73px;

//       background: #fff;

//       padding: 0;

//       border-radius: 3px;
//       border: 1px solid #ebebeb;
//       border-top: none;

//       transform: translateX(50%);

//       right: 88%;

//       min-width: 225px;

//       pointer-events: none;
//       animation-name: fadeIn;
//       animation-duration: 0.4s;

//       ul {
//         padding: 0;
//         margin: 0;

//         li {
//           list-style-type: none;

//           a {
//             padding: 1rem 3rem;
//             text-align: left;
//             display: block;

//             span {
//               margin-right: 15px;

//               img {
//                 height: auto;
//               }
//             }

//             &:hover {
//               background: #f4f4f4;
//             }
//           }

//           .logout {
//             color: #ec6607;
//             position: relative;
//             padding: 1rem 6rem;

//             &::before {
//               position: absolute;

//               content: '';

//               left: 48px;

//               width: 25px;

//               height: 1px;

//               top: 24px;

//               background: #ec6607;
//             }
//           }
//         }
//       }

//       .arrowDrop {
//         width: 0;

//         height: 0;

//         border-left: 15px solid transparent;

//         border-right: 15px solid #a2145d00;

//         border-bottom: 17px solid #fff;

//         position: absolute;

//         top: -17px;

//         margin: 0 auto;

//         left: 0;

//         right: 0;

//         box-shadow: inset 0 3px #000;
//       }
//     }

//     .active.myaccountDropdown {
//       opacity: 1;
//       visibility: visible;
//       pointer-events: auto;
//       transition: opacity 0.3s ease-in-out;
//     }
//   }

//   .searchCancel {
//     color: #fff;

//     p {
//       font-size: 1.3rem;
//     }
//   }

//   .searchHeaderMobile {
//     position: absolute;
//     width: 100%;
//     top: 0;
//     padding: 10px;

//     background: #0079ba;
//     transform: translate3d(0px, -60px, 0px);
//     will-change: transform;
//     transition:
//       transform 0.25s ease-out,
//       -webkit-transform 0.25s ease-out;

//     .searchBar {
//       position: relative;
//       padding: 0;
//       z-index: 99;
//     }
//   }

//   .showSearch.searchHeaderMobile {
//     transform: translate3d(0px, 0px, 0px);
//   }

//   .cart {
//     position: relative;

//     .counts {
//       position: absolute;
//       top: 7px;
//       right: -11px;
//       background-color: #ffec00;
//       width: 15px;
//       height: 15px;
//       border-radius: 20px;
//       font-size: 8px;
//       line-height: 16px;
//       text-align: center;
//     }
//   }

//   .searchIconInner {
//     display: none;
//   }
// }

// .searchBar {
//   position: relative;
//   padding: 15px 0;
//   z-index: 99;

//   @media screen and (min-width: 1023px) and (max-width: 1200px) {
//     width: 90%;
//   }

//   > img {
//     position: absolute;
//     top: 26px;
//     right: 10px;
//     max-width: 18px;
//     caret-color: transparent;
//   }

//   input {
//     width: 100%;
//     height: 40px;
//     background: #f6f6f6;
//     border: none;
//     padding: 0 40px 0 18px;
//     letter-spacing: 1px;
//     color: #000;
//     border-radius: 8px;

//     @media screen and (min-width: 768px) and (max-width: 1024px) {
//       width: 100%;
//       height: 40px;
//       background: #f6f6f6;
//       border: none;
//       padding: 0 30px 0 12px;
//       letter-spacing: 0px;
//       font-size: 0.8rem;
//       color: #000;
//       border-radius: 8px;
//     }
//   }
// }

// .overlay {
//   background: rgba(0, 0, 0, 0.5);
//   position: fixed;
//   height: 100%;
//   width: 100%;
//   top: 0;
//   left: 0;
//   z-index: 90;
// }

// .recentSearchSugestionsBlock {
//   width: 100%;
//   background: #fff;
//   position: absolute;
//   left: 0;
//   top: 50px;
// }

// .searchSugestionsBlock {
//   background: #fff;
//   position: absolute;
//   width: 100%;
//   left: 0;
//   border-radius: 4px;
//   box-shadow: 0 1px 1px #ddd;
//   transition: 0.4s ease;

//   .suggestionRow {
//     display: flex;
//     padding: 15px;
//     flex-wrap: wrap;
//     max-height: 450px;
//     overflow-y: auto;

//     .colLeft {
//       flex: 0 0 100%;
//       max-width: 100%;
//       padding-right: 15px;
//       margin-bottom: 10px;
//     }

//     .colRight {
//       flex: 0 0 100%;
//       max-width: 100%;
//     }
//   }

//   h5 {
//     font-size: 14px;
//     color: lighten($color: #363636, $amount: 50%);
//   }

//   ul {
//     padding: 0;
//     margin: 0;

//     li {
//       list-style-type: none;
//       margin-bottom: 0;
//       line-height: 14px;
//       font-weight: 300;
//       padding: 10px;
//       transition: 0.2s all;
//       font-size: 15px;
//       border-radius: 3px;
//       border-bottom: 1px solid #f4f4f4;

//       &:hover {
//         background: #f4f4f4;
//       }

//       &:last-child {
//         margin-bottom: 0;
//       }

//       strong {
//         margin-left: 2px;
//       }

//       span {
//         margin-right: 20px;
//         vertical-align: 0;

//         img {
//           position: relative;
//           left: auto;
//           top: auto;
//           max-width: 15px;

//           @media screen and (max-width: 991px) {
//             top: 0px !important;
//           }
//         }
//       }
//     }

//     .total_products {
//       color: lighten($color: #424453, $amount: 50%);
//     }

//     .arrow-up {
//       float: right;
//       margin-right: 0 !important;
//     }
//   }
// }

// .exploreMatching {
//   a {
//     width: 100%;
//     display: block;
//     background: #f4f4f4;
//     padding: 15px;
//     border-radius: 0 0 4px 4px;
//   }
// }

// .searchSugestionsBlock {
//   .suggestionRow {
//     .colLeft {
//       flex: 0 0 25%;
//       max-width: 25%;
//       padding-right: 15px;

//       .searchSuggestionsList {
//         border: none;

//         .searchSuggestions {
//           ul {
//             li {
//               padding: 14px 10px;

//               span {
//                 float: left;
//               }

//               .arrow-up {
//                 float: right;
//               }
//             }
//           }
//         }
//       }
//     }

//     .colRight {
//       flex: 0 0 75%;
//       max-width: 75%;
//     }
//   }

//   .search_suggestions_list {
//     border-right: 1px solid #ebebeb;
//   }
// }

.searchBar {
  width: 65%;
  z-index: 99;

  .searchPlaceholder {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    background: #f5f4f5;
    border-radius: 10px;
    border: none;
    padding: 0 30px 0 10px;
    letter-spacing: 0px;
    color: #424453;
    border-radius: 8px;
    line-height: 4;
    overflow: hidden;

    @media screen and (max-width: 430px) {
      font-size: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    span {
      margin-right: 30px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  // .searchIcon {
  //   opacity: 0.5;
  // }
}

// Header css End
// @media screen and (max-width: 991px) {
// Mobile header css
// .header {
//   background: #0079ba;

//   .mobileHeader {
//     justify-content: space-between;
//   }

//   .desktopLogo {
//     padding-right: 10px !important;
//   }

//   .decathlonLogo {
//     img {
//       max-width: 100px;
//     }
//   }

//   .signin {
//     text-transform: uppercase;
//     color: #fff;
//     border: 1px solid;
//     padding: 3px 10px !important;
//     border-radius: 3px;
//     display: table;
//     margin-top: 3px;
//     margin-right: 1rem !important;
//     white-space: nowrap;

//     img {
//       display: none !important;
//     }

//     p {
//       color: #fff;
//       font-size: 11px;
//     }
//   }

//   .cart {
//     margin-top: 6px;

//     p {
//       color: #fff;
//     }
//   }

//   .pincode {
//     position: unset;
//     right: 0%;
//   }

// .searchBar {
//   margin-right: 10px;
//   width: 65%;
//   padding: 0px;
//   flex: 0 0 72%;
//   z-index: 99;

//   .searchPlaceholder {
//     display: flex;
//     align-items: center;
//     width: 100%;
//     height: 40px;
//     background: #f5f4f5;
//     border-radius: 10px;
//     border: none;
//     padding: 0 30px 0 10px;
//     letter-spacing: 0px;
//     color: #424453;
//     border-radius: 8px;
//     line-height: 4;
//     overflow: hidden;
//     font-size: 1rem;

// @media screen and (max-width: 425px) {
//   font-size: 0.75rem;
//   white-space: nowrap;
//   text-overflow: ellipsis;
// }

//     span {
//       margin-right: 30px;
//       text-overflow: ellipsis;
//       overflow: hidden;
//     }
//   }

//   img {
//     top: 12px;
//     opacity: 0.5;
//     caret-color: transparent;
//   }
// }

// .verticalNav {
//   .menuIcon {
//     span {
//       &:nth-child(1),
//       &:nth-child(3) {
//         background-color: #fff;
//         height: 2.4px;
//         width: 30px;
//       }
//     }
//   }
// }

// .showSearch.searchHeaderMobile {
//   position: fixed;
//   z-index: 9999999;
//   transform: translate3d(0, 0, 0);

//   input {
//     height: 40px;
//     border: none;
//     letter-spacing: 0;
//     font-size: 0.925rem !important;
//     border-radius: 8px;

//     @media screen and (max-width: 425px) {
//       font-size: 0.77rem !important;
//     }
//   }
// }

// .add {
//   transition: 0.2s ease;
//   height: 40px;
// }

// .remove {
//   transition: 0.2s ease;
//   display: none;
//   height: 0;
//   opacity: 0;
// }

// .searchIconInner {
//   display: inline;
// }

// &.spaceMobile {
//   padding-bottom: 1rem;

//   @media screen and (min-width: 768px) {
//     padding-bottom: 0rem;
//   }
// }
// }

// .searchSugestionsBlock {
//   background: #f4f4f4;
//   position: fixed;
//   top: 58px;
//   height: 100%;
//   overflow-y: auto;

//   .suggestionRow {
//     max-height: inherit;
//     padding-bottom: 9rem;
//   }

//   .search_suggestions_list {
//     border: none;
//   }

//   .commonBg {
//     background: #fff;
//     padding: 15px;

//     &.col-right {
//       margin-bottom: 7rem;
//     }
//   }

//   .exploreMatching {
//     position: fixed;
//     bottom: 0;
//     width: 100%;
//     left: 0;
//     z-index: 1040;

//     a {
//       background: #fff;
//     }
//   }
// }

.onScrollHeader {
  opacity: 0;
  transform: translate3d(0px, -70px, 0);
  will-change: transform;
  transition:
    transform 0.25s ease-out,
    -webkit-transform 0.25s ease-out;
  background: white;
  height: 0;
  display: flex;
  align-items: center;
  visibility: hidden;
  position: fixed;

  &.fixed {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
    position: fixed;
    top: 0;
    z-index: 30;
    width: 100%;
    visibility: visible;
    height: auto;
  }

  .searchBar {
    padding: 0;
    z-index: 99;
    position: relative;

    img {
      top: 9px;
      caret-color: transparent;
    }

    .searchPlaceholder {
      width: 100%;
      height: 39px;
      background: #f5f4f5;
      border-radius: 10px;
      border: none;
      padding: 0 0 0 10px;
      letter-spacing: 0.25px;
      color: #424453;
      border-radius: 3px;
      line-height: 39px;
      font-size: 01rem;

      @media screen and (max-width: 450px) {
        font-size: 10px;
        padding: 1px 0 0px 10px;
        border-radius: 20px;
      }

      // @media screen and (min-width: 425px) and (max-width: 768px) {
      //   border-radius: 30px;
      //   padding-left: 20px;
      //   font-size: 0.8rem;
      //   top: 0px !important;
      // }
    }
  }
}

// .sticky-top.positionRelativeMob {
//   position: relative !important;
//   z-index: 1050;
// }

// .usefulLinks {
//   img {
//     margin-bottom: 2px;
//     height: 24px;
//   }

//   .myaccount_dropdown {
//     display: none;
//   }
// }
// }

// .searchBarWrapper {
//   margin-left: 0%;
//   min-width: 55%;

//   @media screen and (min-width: 1023px) and (max-width: 1200px) {
//     min-width: 50%;
//     margin-right: 20px;
//   }
// }

// @media screen and (min-width: 768px) and (max-width: 1050px) {
//   input#search {
//     width: 100%;
//     height: 40px;
//     background: #f6f6f6;
//     border: none;
//     padding: 0 30px 0 12px;
//     letter-spacing: 0px;
//     font-size: 0.8rem;
//     color: #000;
//     border-radius: 8px;
//   }
// }
